:root {
  --background: #f0f0f0;

  /* Blues */
  --dark: #1968a2;
  --waste: #2693bc;
  --medium: #bed3e1;
  --light: #dee9f0;

  /* Error */
  --red: #f3d4d3;
}

/* General styles and colors.                                                 */

* {
  box-sizing: border-box;
  color: var(--dark);
  font-size: 14px;
  margin: 0;
  padding: 0;
}

a {
  color: var(--dark);
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  text-decoration: none;
}

a:hover {
  background-color: var(--light);
  border-radius: 20px;
}

body {
  background-color: var(--background);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
}

button:disabled:hover {
  background-color: white;              /* Removes the highlight color.       */
}

button:hover {
  background-color: var(--light);
  cursor: pointer;
}

h1 {
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 16px;
}

h3 {
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 8px 8px;
}

p {
  font-size: 12px;
  margin-left: 8px;
}

.mat-mdc-tooltip {
  white-space: pre-line;                /* Allow line breaks in tooltips.     */
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  background-color: var(--light);
  color: var(--waste);
}

.mat-mdc-snack-bar-container {
   --mdc-snackbar-container-color: var(--red);
   --mdc-snackbar-supporting-text-color: var(--dark);
}

.width-2  { display: inline-block; width:  2%; }
.width-5  { display: inline-block; width:  5%; }
.width-8  { display: inline-block; width:  8%; }
.width-10 { display: inline-block; width: 10%; }
.width-12 { display: inline-block; width: 12%; }
.width-15 { display: inline-block; width: 15%; }
.width-18 { display: inline-block; width: 18%; }
.width-20 { display: inline-block; width: 20%; }
.width-22 { display: inline-block; width: 22.5%; }
.width-25 { display: inline-block; width: 25%; }
.width-30 { display: inline-block; width: 30%; }
.width-35 { display: inline-block; width: 35%; }
.width-40 { display: inline-block; width: 40%; }
.width-45 { display: inline-block; width: 45%; }
.width-50 { display: inline-block; width: 50%; }
.width-55 { display: inline-block; width: 55%; }
.width-60 { display: inline-block; width: 60%; }
.width-65 { display: inline-block; width: 65%; }
.width-70 { display: inline-block; width: 70%; }
.width-75 { display: inline-block; width: 75%; }
.width-80 { display: inline-block; width: 80%; }
.width-85 { display: inline-block; width: 85%; }
.width-90 { display: inline-block; width: 90%; }
.width-95 { display: inline-block; width: 95%; }
.width-100 { display: inline-block; width: 100%; }
